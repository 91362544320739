@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Colores */

  --tw-bg-opacity: 1;
  --body-color: #ededed;
  --sidebar-color: #fff;
  --primary-color: rgb(44, 53, 91);
  --primary-color-ligth: #f3f3f3;
  --secundary-color: rgb(99, 115, 177);
  --secundary-color-ligth: #f5f5f5;
  --text-color: #092f47;
  --toggle-color: rgb(214, 214, 214);
  --nav-color: rgb(239, 239, 239);


}

body{
  margin: 0;
  padding: 0 !important;
}

.collapse{
  visibility: visible;
}

.navbar {
background: var(--primary-color) !important;
}

body {
  margin: 0;
  background: var(--body-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
  position: absolute;
  left: 10px;
  /* Adjust this value to position the icon properly */
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}


.w-100 {
  width: 100px !important;
}

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-container input {
  padding-left: 30px;
  /* Adjust this value based on the icon size and spacing */
}

.row {
  margin: 0 !important;
}

.col-md-12 {
  padding: 0;
}

.loading-fade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.547);
  z-index: 10000;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10001;
  transform: translate(-50%, -50%);
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading h4 {
  color: var(--primary-color-ligth);
  background: var(--primary-color);
  padding: 4px 15px 4px 15px;
  border-radius: 4px;
  font-weight: 400;
  text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}


/* LOGIN */


.LoginHome {
  background: #eae8e8;
  position: relative;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}


.containerHome {
  background: white;
  border-radius: 10px;
  margin: 10px;
  padding: 30px;
  height: 80%;
  display: flex;
  /* Use flexbox layout */
  align-items: center;
  /* Align items vertically to the center */
  justify-content: center;
}

.login-header {
  text-align: center;
}

.login-header img {
  height: 50px;
}


.login-header h1 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.loginInput {
  border: none;
  border-bottom: 2px solid var(--primary-color);
  height: 30px;
  font-size: 15px;
  background: #ffffff;
  width: 100%;
}


.loginInput:focus {
  outline: none;
}


.buttonLogin {
  margin-top: 20px;
  width: 100%;
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all .3s ease;
}

.buttonLogin:hover {
  background: var(--primary-color-ligth);
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}

.brand {
  color: grey;
  font-size: 10px;
  text-decoration: none;
  margin-top: 20px;
}


/* NavBar */

.logoNav {
  height: 30px;
  margin-right: 10px;
}

.logout {
  border: none;
  color: var(--primary-color);
  background: var(--primary-color-ligth);
  padding: 5px;
  border-radius: 10px;
  transition: all .3s ease;
}

.logout:hover {
  background: var(--secundary-color);
  color: white;
  cursor: pointer;
}




.contenidoPagina {
  padding: 20px;
}


.dataTable {
  background: var(--secundary-color-ligth);
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;
}

.title-section {
  width: 100%;
  background: var(--primary-color);
  color: white;
  text-transform: capitalize;
  padding: 10px;
}

.btn-green {
  background: var(--secundary-color);
  color: white;
  border: none;
  padding: 8px;
  width: 80px;
  transition: all .3s ease;
}

.btn-green:hover {
  cursor: pointer;
  background: var(--secundary-color-ligth);
  color: var(--secundary-color);
  font-weight: 600;
}

.form-container{
  padding: 10px;
  margin-bottom: 10px;
  background: white;

}

.contenedorTm {
  width: 100%;
  padding: 10px;
}

.subNav {
  width: 100%;
  padding: 5px;
  background: white;
  padding-left: 15px;
  font-size: 12px;
  font-weight: 600;
  color: var(--secundary-color);
  text-transform: uppercase;
}

.table-title {
  width: 100%;
  background: var(--secundary-color);
  color: #f1f1f1;
  font-size: 15px;
  margin: 0;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-header {
  margin: 0;
}


.btn-add {
  border: none;
  background: var(--primary-color);
  color: var(--primary-color-ligth);
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: all .3s ease;
}

.btn-add:hover {
  color: var(--primary-color);
  background: var(--secundary-color-ligth);
}

.modalBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  cursor: pointer;
  background: #9292928a;
  transition: all ease .3s;
}

.modalCard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 1002;
  border-radius: 15px;
  background: #ffffff;
  overflow-y: scroll;
  padding: 30px;
  max-height: 600px;
}


body{
  min-height: 100vh;
  padding-bottom: 10px;
  height: auto;
}


.modalTitle {
  color: var(--secundary-color);
  font-size: 18px;
  padding: 0;
  font-weight: 600;
  margin: 0;
}

.bg-blue {
  background: var(--secundary-color);
  height: 3px;
}

.label-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--secundary-color);
  margin: 0;
}

.label-value {
  font-size: 15px;
  font-weight: 300;
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 5px;
  padding: 4px;
  padding-left: 10px;
}

.label-white {
  font-size: 15px;
  font-weight: 300;
  background: white;
  width: 100%;
  border-radius: 15px;
  padding: 4px;
  overflow: hidden;
  padding-left: 10px;
  height: 30px;
  margin: 0;
}

.dropdown-item {}

.nav-link {
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary-color-ligth) !important;
}

.btn-blue {
  background: var(--secundary-color);
  color: var(--secundary-color-ligth);
  border: none;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  transition: all .3s ease;
}

.btn-blue:hover {
  background-color: #4b80da;
  cursor: pointer;
}


.subcliente-card {
  width: 100%;
  padding: 10px;
  margin-top: 20px !important;
  background: rgb(244, 244, 244);
}

.btn-delete {
  background: rgb(205, 46, 46);
  color: white;
  border: none;
  width: 100%;
  height: 30px;
  margin-top: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: all ease .3s;
}

.btn-delete:hover {
  background: rgb(202, 85, 85);
}